.App {
  text-align: center;
}

.App-logo {
  height: 32px;
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div.card__background {
  background-color: #b9e0f6;
  height: 70px;
  border: 2px #b9e0f6 solid;
}
p.card__text {
  color: #black;
  font: 14px sans-serif;
  padding: 10px;
  margin: 0;
  text-align: left;
  
}
rect.card { fill: url(#gradient); }
.stop1 { stop-color: #ff5c69; }
.stop2 { stop-color: #ff4252; }
.stop3 { stop-color: #ed2637; }