.super-app-theme--header {
    background-color: #f5f5f5;
}

.super-app-theme--row {
    background-color: #FFFFFF;
}

.MuiDataGrid-columnHeader {
    background-color: #f5f5f5;
}

.MuiDataGrid-filler {
    background-color: #f5f5f5;

}